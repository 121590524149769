.header {
  width: 100%;
  height: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-site-color);
  position: relative;
}

.image {
  width: 145px;
  height: 145px;
  margin-top: 16px;
}

.canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}