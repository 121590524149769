.homeContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: var(--primary-site-color);
}

/* Desktop layout */
@media (min-width: 1024px) {
  .homeContainer {
    flex-direction: row;
  }
}
